import React, { useState, useEffect } from "react";
import axios from "axios";

const AllNotifications = ({ setMentorReviews }) => {
  const [allNotifications, setAllNotifications] = useState([]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/analytics"
        );
        setAllNotifications(response.data.recentActivities?.reverse());
      } catch (error) {}
    };
    fetchAnalytics();
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        width: "75rem",
        height: "65rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        left: "32rem",
        top: "3rem",
        pointerEvents: "all",
        zIndex: 999,
        padding: "1.8rem",
      }}
      className="all-members"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom:"1.5rem"
        }}
      >
        <h2
          style={{
            fontSize: "1.85rem",
            fontWeight: "700",
          }}
        >
          All Notifications
        </h2>
        <button
          style={{
            border: "none",
            background: "none",
            padding: "0",
          }}
          onClick={() => setMentorReviews(false)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 6L18.7742 18.7742"
              stroke="#14142B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 18.7734L18.7742 5.99924"
              stroke="#14142B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <table className="all-notification recent-actions">
        <thead>
          <tr
            style={{
              backgroundColor: "#BAE6D4",
            }}
          >
            <th
              colSpan="2"
              style={{
                width: "29%",
                paddingLeft: "1.2rem",
              }}
            >
              User
            </th>
            <th
              colSpan="2"
              style={{
                width: "30%",
              }}
            >
              Actions
            </th>
            <th
              colSpan="2"
              style={{
                width: "20%",
              }}
            >
              Price
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allNotifications?.map((item, index) => {
            let firstLetter = item?.fullName?.charAt(0);
            let userPicture;

            if (item?.picture?.includes("images")) {
              userPicture = (
                <div
                  style={{
                    width: "130%",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "2rem",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    src={item.picture}
                    alt="User"
                  />
                </div>
              );
            } else {
              userPicture = (
                <div
                  style={{
                    background: "#c30532",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    height: "2rem",
                    width: "2rem",
                  }}
                >
                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: "0.8rem",
                    }}
                  >
                    {firstLetter}
                  </p>
                </div>
              );
            }

            return (
              <tr key={index}>
                <td
                  style={{
                    paddingLeft: "0.8rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <span>{userPicture}</span>
                    <span>{item.fullName}</span>
                  </div>
                </td>
                <td></td>
                <td>{item.what}</td>
                <td></td>
                <td
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  {item.amount}
                </td>
                <td></td>
                <td
                  style={{
                    color: "#C30532",
                    fontWeight: "500",
                  }}
                >
                  {item.status}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllNotifications;
