import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const ServicePackagesForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  isLoading,
  setIsLoading,
}) => {
  const [servicePackagesFormData, setServicePackagesFormData] = useState({
    title: "",
    currency: "",
    amount: "",
    details: [""],
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editMode && itemToEdit) {
      try {
        // Assume itemToEdit.details is already a JSON array, so no need for parsing
        const parsedDetails = itemToEdit.details;

        // Convert the array of objects into "key: value" strings
        const formattedDetails = parsedDetails.map((detail) => {
          const key = Object.keys(detail)[0];
          const value = detail[key];
          return `${key}: ${value}`;
        });

        setServicePackagesFormData({
          id: itemToEdit.id,
          title: itemToEdit.title,
          currency: itemToEdit.currency,
          amount: itemToEdit.amount,
          details: formattedDetails,
        });
      } catch (error) {
        console.error("Failed to process details:", error);
        // Fallback to an empty input if something goes wrong
        setServicePackagesFormData({
          id: itemToEdit.id,
          title: itemToEdit.title,
          currency: itemToEdit.currency,
          amount: itemToEdit.amount,
          details: [""],
        });
      }
    }
  }, [editMode, itemToEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setServicePackagesFormData({
      ...servicePackagesFormData,
      [name]: value,
    });
  };

  const handleDetailChange = (index, value) => {
    const newDetails = [...servicePackagesFormData.details];
    newDetails[index] = value;
    setServicePackagesFormData({
      ...servicePackagesFormData,
      details: newDetails,
    });
  };

  const handleAddDetail = () => {
    setServicePackagesFormData({
      ...servicePackagesFormData,
      details: [...servicePackagesFormData.details, ""],
    });
  };

  const deleteDynamicInput = (indexToRemove, arrayName) => {
    setServicePackagesFormData((prevState) => ({
      ...prevState,
      [arrayName]: prevState[arrayName].filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const validateForm = (formData) => {
    const isAnyFieldEmpty = Object.entries(formData).some(
      ([key, value]) => key !== "id" && value === ""
    );

    if (isAnyFieldEmpty) {
      setInputError(true);
      return false;
    }

    const areDetailsValid = formData.details.every((detail) => {
      return detail.includes(":");
    });

    if (!areDetailsValid) {
      setInputError(true);
      setErrorMessage("Each package detail must be in the format 'key: value'");
      return false;
    }

    setInputError(false);
    return true;
  };

  const addPackage = async (packagesData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/addFetechPackageDeals",
        packagesData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const editPackage = async (packagesData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/addFetechPackageDeals",
        packagesData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const postPackage = async (payload) => {
    try {
      editMode ? await editPackage(payload) : await addPackage(payload);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm(servicePackagesFormData)) {
      return;
    }

    const formattedDetails = servicePackagesFormData.details.map((detail) => {
      const [key, value] = detail.split(":").map((item) => item.trim());
      return { [key]: value };
    });

    const payload = {
      ...servicePackagesFormData,
      details: formattedDetails,
    };

    postPackage(payload);
    // console.log(payload);
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(1);
  };

  return (
    <div className="resource-form event-form programme-form">
      {errorMessage ? (
        <ErrorMessage message={`Error submitting form : ${errorMessage}`} />
      ) : (
        <form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
          {!formSubmitted ? (
            <div>
              <label>
                Title:
                <input
                  type="text"
                  name="title"
                  value={servicePackagesFormData.title}
                  onChange={handleInputChange}
                  placeholder="Nill"
                  required
                />
              </label>
              <br />
              <label>
                Package Details:
                {servicePackagesFormData.details &&
                  servicePackagesFormData.details.length > 0 &&
                  servicePackagesFormData.details.map((detail, index) => (
                    <div
                      key={index}
                      style={index === 0 ? { marginTop: "1rem" }:{}}
                      className="input-options"
                    >
                      <input
                        type="text"
                        placeholder="e.g, Delivery Time: 90Days"
                        value={detail}
                        onChange={(e) =>
                          handleDetailChange(index, e.target.value)
                        }
                        className="dynamic-input"
                        required
                      />
                      <button
                        type="button"
                        className="delete-input"
                        onClick={() => deleteDynamicInput(index, "details")}
                      >
                        x
                      </button>
                    </div>
                  ))}
                <button type="button" onClick={handleAddDetail}>
                  + Add more detail
                </button>
              </label>
              <br />
              <label>
                Amount:
                <input
                  type="text"
                  name="amount"
                  value={servicePackagesFormData.amount}
                  onChange={handleInputChange}
                  placeholder="Nill"
                  required
                />
              </label>
              <br />
              <label>
                Currency:
                <input
                  type="text"
                  name="currency"
                  value={servicePackagesFormData.currency}
                  onChange={handleInputChange}
                  placeholder="Nill"
                  required
                />
              </label>
              <br />
              <button
                type="submit"
                style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                className="continue btn2Style"
              >
                {editMode ? "Update service package" : "Create service package"}
              </button>
              {inputError && (
                <p className="input-error">⛔ Please fill in all details</p>
              )}
            </div>
          ) : (
            <FormSuccessfulMessage
              text={
                editMode
                  ? "Service Package updated successfully"
                  : "Service Package added successfully"
              }
              onclick={handleFormHomeBtn}
            />
          )}
        </form>
      )}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default ServicePackagesForm;
