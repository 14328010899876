import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminDashboard from "./components/pages/AdminDashboard";
import AdminLogin from "./components/AdminLogin";
import Logout from "./components/Logout";
import "./styles/app.css";

const App = () => {
  const [financeBudget, setFinanceBudget] = useState("");
  const [adminDetails, setAdminDetails] = useState("");

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AdminLogin
            setAdminDetails={setAdminDetails}
            financeBudget={financeBudget}
            setFinanceBudget={setFinanceBudget}
              adminDetails={adminDetails}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <AdminDashboard
              financeBudget={financeBudget}
              setFinanceBudget={setFinanceBudget}
              adminDetails={adminDetails}
            />
          }
        />
        <Route
          path="/logout"
          element={
            <Logout
            setAdminDetails={setAdminDetails}
              setFinanceBudget={setFinanceBudget}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
