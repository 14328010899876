import React, { useEffect } from "react";
import axios from "axios";

export const AllCoupon = ({
  api,
  payload,
  setIsLoading,
  setApiData,
  setErrorMessage,
  children,
}) => {
  useEffect(() => {
    const fetchProgram = async () => {
      let response;
      try {
        setIsLoading(true);
        api === "https://9jadelivery.com/femaletechMobile/public/api/getCoupon"
          ? (response = await axios.get(api, {
              params: {
                email: "Bukky@femaletechpreneur.com",
                canGetCoupon: true,
              },
            }))
          : (response = await axios.get(api, {
              params: {
                email: "Bukky@femaletechpreneur.com",
                getCouponedUsers: true,
              },
            }));
        setApiData(response.data);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(`Error fetching program data: ${error.message}`);
        setIsLoading(false);
      }
    };

    fetchProgram();
  }, [api, setIsLoading, setApiData, setErrorMessage]);

  return <>{children}</>;
};

export function Loader() {
  return <p className="loader">Loading...</p>;
}

export function ErrorMessage({ message }) {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
}

export default AllCoupon;
