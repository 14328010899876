import NotificationIcon from "../assets/icons/notification.svg";
import SearchIcon from "../assets/icons/search.svg";
// import User from "../assets/images/user.png";

const AdminNavbar = ({ adminDetails, financeBudget }) => {
  return (
    <header>
      <div className="greetings">
        {financeBudget ? <p>Hi, Super Admin</p> : <p>Hi, Admin</p>}
      </div>
      <div>
        <Search />
        <Notification />
        <Greetings adminDetails={adminDetails} />
      </div>
    </header>
  );
};

const Greetings = ({ adminDetails }) => {
  const storedAdminName = localStorage.getItem("adminName");
  const adminName = adminDetails ? adminDetails.name : storedAdminName;
  const nameToUse = adminName.split(" ")[0];

  const storedAdminPic = localStorage.getItem("adminPic");
  const adminPicToUse = adminDetails ? adminDetails.picture : storedAdminPic;

  let firstLetter = nameToUse?.charAt(0);
  let userPicture;

  if (adminPicToUse?.includes("images")) {
    userPicture = (
      <div
        style={{
          width: "3rem",
          marginRight: "0.7rem",
        }}
      >
        <img
          src={adminPicToUse}
          alt="User"
          style={{
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  } else {
    userPicture = (
      <div
        style={{
          background: "#c30532",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          height: "3rem",
          width: "3rem",
          marginRight: "0.7rem",
        }}
      >
        <p
          style={{
            color: "#ffffff",
            fontSize: "1.4rem",
          }}
        >
          {firstLetter}
        </p>
      </div>
    );
  }

  return (
    <div className="greetings">
      {userPicture}
      <p>Hi, {nameToUse}</p>
    </div>
  );
};

export const Search = () => {
  return (
    <div className="search">
      <input type="text" placeholder="Search..." />
      <img src={SearchIcon} alt="Search Icon" />
    </div>
  );
};

const Notification = () => {
  return (
    <div className="notification">
      <img src={NotificationIcon} alt="Notification Icon" />
    </div>
  );
};

export default AdminNavbar;
