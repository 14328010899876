import axios from "axios";

const DeleteConfirmation = ({
  setConfirmBox2,
  eventID,
  api,
  setEditMode,
  isActive,
  menu2Active,
  setMenu2Active,
  menuLength,
  setcoverImagePreview,
}) => {
  const axiosInstance = axios.create({
    baseURL: "https://9jadelivery.com/femaletechMobile/public/api",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  const handleDelete = async (id, api) => {
    if (
      api ===
      "https://9jadelivery.com/femaletechMobile/public/api/deleteFetechPackages"
    ) {
      try {
        await axiosInstance.post(api, { planId: id });
        setEditMode(false);
        setConfirmBox2(false);
        setMenu2Active(2);
      } catch (error) {
        console.error("Error deleting program:", error);
      }
    } else if (
      api ===
      "https://9jadelivery.com/femaletechMobile/public/api/removeCouponedUsersLimit"
    ) {
      try {
        await axiosInstance.post(api, {
          email: "Bukky@femaletechpreneur.com",
          removeCouponedUsersLimit: true,
          userId: id,
        });
        setEditMode(false);
        setConfirmBox2(false);
        setMenu2Active(3);
      } catch (error) {
        console.error("Error deleting program:", error);
      }
    } else if (
      api === "https://9jadelivery.com/femaletechMobile/public/api/removeCoupon"
    ) {
      try {
        await axiosInstance.patch(api, {
          email: "Bukky@femaletechpreneur.com",
          canRemoveCoupon: true,
          id: id,
        });
        setEditMode(false);
        setConfirmBox2(false);
        setMenu2Active(3);
      } catch (error) {
        console.error("Error deleting program:", error);
      }
    } else {
      try {
        await axiosInstance.post(api, { id: id });
        setEditMode(false);
        setConfirmBox2(false);
        setcoverImagePreview(false);
        if (isActive === 2 && menu2Active === 1) {
          setMenu2Active(2);
        } else {
          setMenu2Active(menuLength);
        }
      } catch (error) {
        console.error("Error deleting program:", error);
      }
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "43rem",
        height: "25rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        left: "53rem",
        top: "20rem",
        pointerEvents: "all",
        zIndex: 999,
      }}
    >
      <p
        style={{
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Are you sure <br /> you want to proceed?
      </p>
      <p
        style={{
          fontSize: "2rem",
          textAlign: "center",
          marginTop: "3rem",
        }}
      >
        Please note that this action is irreversible
      </p>

      <div
        style={{
          margin: "2.6rem 0 1rem 0",
          display: "flex",
          gap: "2rem",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            width: "46%",
            marginLeft: "2rem",
            fontWeight: "700",
            background: "none",
            color: "#c3053c",
            textTransform: "none",
            fontSize: "1.2rem",
          }}
          onClick={() => setConfirmBox2(false)}
        >
          Cancel
        </button>

        <button
          style={{
            marginLeft: "0",
            marginRight: "2rem",
            width: "46%",
            fontWeight: "700",
            textTransform: "none",
            fontSize: "1.2rem",
          }}
          className="btn2Style"
          onClick={() => handleDelete(eventID, api)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
