import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorMessage, Loader } from "./AdminStructure1";
import PastEventCover from "../assets/images/resource.png";
import AddPastEventPicturesForm from "./AddPastEventPicturesForm";

const PastEvents = ({
  setEditMode,
  setMenu2Active,
  mentorReviews,
  setMentorReviews,
  onEdit,
  setConfirmBox,
  setIsPastEventPictures,
  isPastEventPictures,
  eventID,
  editMode,
  itemToEdit,
}) => {
  const [pastEvents, setPastEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [descriptionToggle, setDescriptionToggle] = useState(false);
  const [selectedMentorId, setSelectedMentorId] = useState(null);

  useEffect(() => {
    const fetchPastEvents = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/getEvent"
        );
        setPastEvent(response.data);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchPastEvents();
  }, [setIsLoading, setErrorMessage]);

  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul
          style={{ height: "50rem", overflow: "auto" }}
          className="resource-page"
        >
          {pastEvents &&
            pastEvents.past?.length > 0 &&
            pastEvents.past?.map((item, index) => (
              <PastEvent
                pastEvent={item}
                descriptionToggle={descriptionToggle}
                setDescriptionToggle={setDescriptionToggle}
                key={index}
                seeMore={mentorReviews}
                setSeeMore={setMentorReviews}
                selectedMentorId={selectedMentorId}
                setSelectedMentorId={setSelectedMentorId}
                onEdit={onEdit}
                isPastEventPictures={isPastEventPictures}
                setConfirmBox={setConfirmBox}
                setIsPastEventPictures={setIsPastEventPictures}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                eventID={eventID}
                setMenu2Active={setMenu2Active}
                editMode={editMode}
                setEditMode={setEditMode}
                itemToEdit={itemToEdit}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const PastEvent = ({
  pastEvent,
  descriptionToggle,
  selectedMentorId,
  setSelectedMentorId,
  seeMore,
  setSeeMore,
  onEdit,
  isPastEventPictures,
  setConfirmBox,
  setIsPastEventPictures,
  isLoading,
  setIsLoading,
  eventID,
  setMenu2Active,
  editMode,
  setEditMode,
  itemToEdit,
}) => {
  const fullDescription = pastEvent.eventDescription;
  const truncatedDescription = pastEvent.eventDescription
    .split(" ")
    .slice(0, 13)
    .join(" ");

  const handlePastEventDetails = () => {
    setSelectedMentorId(pastEvent.id);
    setSeeMore(true);
  };

  return (
    <>
      <li
        style={{ borderRadius: "6px", width: "31rem", cursor: "pointer" }}
        onClick={handlePastEventDetails}
      >
        <div style={{ display: "block", position: "static", padding: "0" }}>
          <img
            src={
              pastEvent.eventPicture.includes("images")
                ? pastEvent.eventPicture
                : PastEventCover
            }
            style={{
              width: "30rem",
              height: "15rem",
              objectFit: "cover",
            }}
            alt="Past Event"
          />
        </div>
        <div className="resource-content" style={{ padding: "1.7rem 1.5rem" }}>
          <h3>{pastEvent.eventName}</h3>
          <p>
            {descriptionToggle ? fullDescription : truncatedDescription}
            {fullDescription.split(" ").length > 13 && (
              <span>{descriptionToggle ? ` see less` : ` see all`}</span>
            )}
          </p>
          <p style={{ color: "#c30532", marginBottom: "0" }}>
            <span>{pastEvent.date}</span>
            {" . "}
            <span>{pastEvent.time}</span>
          </p>
        </div>
      </li>
      {seeMore && selectedMentorId === pastEvent.id && (
        <SeeAllEventDetails
          pastEvent={pastEvent}
          setSeeMore={setSeeMore}
          onEdit={onEdit}
        />
      )}
      {isPastEventPictures && (
        <AddPastEventPicturesForm
          setConfirmBox={setConfirmBox}
          setIsPastEventPictures={setIsPastEventPictures}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          eventID={eventID}
          setMenu2Active={setMenu2Active}
          editMode={editMode}
          setEditMode={setEditMode}
          itemToEdit={itemToEdit}
        />
      )}
    </>
  );
};

const SeeAllEventDetails = ({ pastEvent, setSeeMore, onEdit }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "43rem",
        height: "45rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        pointerEvents: "all",
        zIndex: "999",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1.6rem 2rem 1.4rem 2rem",
          borderBottom: "1px solid #D7D7D7",
        }}
      >
        <button
          style={{
            background: "none",
            border: "none",
            padding: "0",
          }}
          onClick={() => setSeeMore(false)}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 0.75L0.75 11.25M0.75 0.75L11.25 11.25"
              stroke="#C30532"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div
        style={{
          padding: "1.3rem 2rem 2.8rem 2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            marginBottom: "1.4rem",
          }}
        >
          <div style={{ width: "18%", position: "static", padding: "0" }}>
            <img
              src={
                pastEvent.eventPicture.includes("images")
                  ? pastEvent.eventPicture
                  : PastEventCover
              }
              style={{ width: "100%", borderRadius: "4px" }}
              alt="Mentor Cover"
              className="mindfulness-cover"
            />
          </div>
          <div
            style={{
              width: "80%",
            }}
          >
            <h3
              style={{
                fontSize: "1.5rem",
                lineHeight: "1.3em",
                fontWeight: "700",
                color: "#212529",
              }}
            >
              {pastEvent.eventName}
            </h3>
            <p
              style={{
                margin: "0.6rem 0 1rem 0",
              }}
            >
              {pastEvent.eventDescription}
            </p>
            <p style={{ color: "#c30532", marginBottom: "0" }}>
              <span>{pastEvent.date}</span>
              {" . "}
              <span>{pastEvent.time}</span>
            </p>
          </div>
          <button
            style={{
              background: "none",
              border: "none",
              display: "flex",
              padding: "0",
            }}
            onClick={() =>
              onEdit(
                pastEvent.id,
                "https://9jadelivery.com/femaletechMobile/public/api/getEvent"
              )
            }
          >
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.62467 2.24913L10.7497 4.37413M7.20801 12.1658H12.8747M1.54134 9.33247L0.833008 12.1658L3.66634 11.4575L11.8731 3.25072C12.1387 2.98505 12.2879 2.62478 12.2879 2.24913C12.2879 1.87349 12.1387 1.51322 11.8731 1.24755L11.7513 1.12572C11.4856 0.860134 11.1253 0.710937 10.7497 0.710938C10.374 0.710938 10.0138 0.860134 9.74809 1.12572L1.54134 9.33247Z"
                stroke="#C30532"
                stroke-width="1.0625"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p
              style={{
                marginLeft: "0.5rem",
                fontSize: "1.2rem",
                color: "#c30532",
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            >
              Edit
            </p>
          </button>
        </div>
        <div>
          <ul className="resource-page">
            {pastEvent.pastEventPictures?.length > 0 &&
              pastEvent.pastEventPictures?.map((item, index) => (
                <li style={{ width: "45%", border: "none" }}>
                  {" "}
                  {item.includes("images") && (
                    <img
                      src={item}
                      style={{ width: "100%", borderRadius: "6px" }}
                      alt="Past Event"
                    />
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PastEvents;
