import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const AdminSubscriptionPlanForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  isLoading,
  setIsLoading,
}) => {
  const [planTypeFormData, setPlanTypeFormData] = useState({
    planType: null,
    amount: "",
    currency: "",
    benefit: [""],
    role: "",
    state: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editMode) {
      setPlanTypeFormData((prevFormData) => ({
        ...prevFormData,
        id: itemToEdit.id,
        planType: itemToEdit.planType,
        amount: itemToEdit.amount,
        currency: itemToEdit.currency,
        benefit: Array.isArray(itemToEdit.benefit)
          ? itemToEdit.benefit
          : [itemToEdit.benefit || ""],
        role: itemToEdit.role,
        state: itemToEdit.state,
      }));
    }
  }, [editMode, itemToEdit]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;

    setPlanTypeFormData({
      ...planTypeFormData,
      [name]: value,
    });
  };

  function handleBenefitChange(index, value) {
    let newBenefits;

    newBenefits = [...planTypeFormData.benefit];
    newBenefits[index] = value;
    setPlanTypeFormData((prevState) => ({
      ...prevState,
      benefit: newBenefits,
    }));
  }

  const addBenefit = () => {
    setPlanTypeFormData((prevState) => ({
      ...prevState,
      benefit: [...prevState.benefit, ""],
    }));
  };

  const deleteDynamicInput = (indexToRemove, arrayName) => {
    setPlanTypeFormData((prevState) => ({
      ...prevState,
      [arrayName]: prevState[arrayName].filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const postResource = async (subscriptionData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/addFetechPackages",
        subscriptionData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const editResource = async (subscriptionData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/editFetechPackages",
        subscriptionData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const validateForm = (planTypeFormData) => {
    const isAnyFieldEmpty = Object.entries(planTypeFormData).some(
      ([key, value]) => {
        return value === "";
      }
    );

    if (isAnyFieldEmpty) {
      setInputError(true);
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateForm(planTypeFormData);
    addResource(planTypeFormData);

    // console.log(planTypeFormData)
    setInputError(false);
  };

  const addResource = async (payload) => {
    try {
      editMode ? await editResource(payload) : await postResource(payload);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(1);
  };

  return (
    <div className="resource-form event-form programme-form">
      {errorMessage ? (
        <ErrorMessage
          message={`Error submitting form : ${errorMessage}`}
        />
      ) :(<form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
        {!formSubmitted ? (
          <div>
            <label>
              Plan type:
              <input
                type="text"
                name="planType"
                value={planTypeFormData.planType}
                onChange={handleInputChange}
                placeholder="Nill"
              />
            </label>
            <br />
            <label>
              Benefits:
              {planTypeFormData.benefit &&
                planTypeFormData.benefit.length > 0 &&
                planTypeFormData.benefit.map((benefit, index) => (
                  <div key={index} style={index === 0 ? { marginTop: "1rem" }:{}} className="input-options">
                    <input
                      type="text"
                      value={benefit}
                      onChange={(e) =>
                        handleBenefitChange(index, e.target.value)
                      }
                      placeholder={`Benefit ${index + 1}`}
                      className="dynamic-input"
                    />
                    <button
                      type="button"
                      className="delete-input"
                      onClick={() => deleteDynamicInput(index, "benefit")}
                    >
                      x
                    </button>
                  </div>
                ))}
              <button type="button" onClick={addBenefit}>
                + Add another benefit
              </button>
            </label>
            <br />
            <label>
                Role:
                <select
                  name="role"
                  value={planTypeFormData.role}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Member">Member</option>
                  <option value="Partner">Partner</option>
                </select>
              </label>
              <br />
              <label>
                State:
                <select
                  name="state"
                  value={planTypeFormData.state}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Active">Active</option>
                </select>
              </label>
            <br />
            <label>
              Amount:
              <input
                type="text"
                name="amount"
                value={planTypeFormData.amount}
                onChange={handleInputChange}
                placeholder="Nill"
              />
            </label>
            <br />
            <label>
              Currency:
              <input
                type="text"
                name="currency"
                value={planTypeFormData.currency}
                onChange={handleInputChange}
                placeholder="Nill"
              />
            </label>

            <br />
            <button type="submit" style={{ cursor: isLoading ? "not-allowed" : "pointer" }} className="continue btn2Style">
              {editMode
                ? "Update subscription plan"
                : "Create subscription plan"}
            </button>
            {inputError && (
              <p className="input-error">⛔ Please fill in all benefits</p>
            )}
          </div>
        ) : (
          <FormSuccessfulMessage
            text={
              editMode
                ? "Subscription plan updated successfully"
                : "Subscription plan added successfully"
            }
            onclick={handleFormHomeBtn}
          />
        )}
      </form>)}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
}

export default AdminSubscriptionPlanForm;
