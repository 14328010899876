import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const TimePicker = ({ selectedTime = null, onTimeChange }) => {
  const [isTimepickerOpen, setTimepickerOpen] = useState(false);

  const handleTimeChange = (time) => {
    if (time) {
      onTimeChange(moment(time).format("HH:mm:ss"));
      setTimepickerOpen(false);
    } else {
      onTimeChange(null);
    }
  };

  const parsedTime = selectedTime
    ? moment(selectedTime, "HH:mm:ss").toDate()
    : null;

  return (
    <div className="calendar">
      <DatePicker
        selected={parsedTime}
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm:ss"
        placeholderText="Select a time"
        open={isTimepickerOpen}
        onFocus={() => setTimepickerOpen(true)}
        onBlur={() => setTimepickerOpen(false)}
      />
    </div>
  );
};

export default TimePicker;
