import React, { useState } from "react";
import { ErrorMessage, Loader } from "./AdminStructure1";
import MentorPlaceholder from "../assets/images/resource.png";

export const UpcomingMentorSession = ({
  apiData,
  isLoading,
  errorMessage,
}) => {
  const [selectedMentorId, setSelectedMentorId] = useState(null);
  const [meetingLink, setMeetingLink] = useState("");

  const handleInputChange = ({ target }) => {
    setMeetingLink(target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="mentorship-page resource-page">
          {apiData &&
            apiData.length > 0 &&
            apiData.map((item, index) => (
              <UpcomingSession
                upcomingSession={item}
                key={index}
                selectedMentorId={selectedMentorId}
                setSelectedMentorId={setSelectedMentorId}
                onSubmit={handleSubmit}
                onInputChange={handleInputChange}
                meetingLink={meetingLink}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const UpcomingSession = ({
  upcomingSession,
  selectedMentorId,
  setSelectedMentorId,
  onSubmit,
  onInputChange,
  meetingLink,
}) => {
  return (
    <>
      <li
        style={{
          padding: "1.2rem",
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "0.7rem",
        }}
      >
        <div style={{ position: "static", padding: "0" }}>
          <div style={{ width: "20%" }}>
            {upcomingSession && upcomingSession.mentorImage && (
              <img
                src={
                  upcomingSession.mentorImage.includes("images")
                    ? upcomingSession.mentorImage
                    : MentorPlaceholder
                }
                style={{ width: "100%" }}
                alt="Mentor"
                className="mindfulness-cover"
              />
            )}
          </div>
          <div
            style={{
              width: "80%",
              marginLeft: "1rem",
            }}
          >
            <h3
              style={{
                fontSize: "1.4rem",
                lineHeight: "1.3em",
                fontWeight: "700",
                marginBottom: "0.5rem",
              }}
            >
              {upcomingSession.mentorName}
            </h3>
            <p>
              {`Profession: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {upcomingSession.mentorProfession}
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #B1B1B1",
            paddingBottom: "0.7rem",
          }}
        >
          <div style={{ width: "20%", padding: "0" }}>
            {upcomingSession && upcomingSession.menteeImage && (
              <img
                src={
                  upcomingSession.menteeImage.includes("images")
                    ? upcomingSession.menteeImage
                    : MentorPlaceholder
                }
                style={{ width: "100%" }}
                alt="Mentor"
                className="mindfulness-cover"
              />
            )}
          </div>
          <div
            style={{
              width: "80%",
              marginLeft: "1rem",
            }}
          >
            <h3
              style={{
                fontSize: "1.4rem",
                lineHeight: "1.3em",
                fontWeight: "700",
                marginBottom: "0.5rem",
              }}
            >
              {upcomingSession.menteeName}
            </h3>
            <p>
              {`Profession: `}
              <span
                style={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {upcomingSession.menteeProfession}
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            margin: "0.5rem 0",
          }}
        >
          <p
            style={{
              fontSize: "1.3rem",
            }}
          >
            Session Date & Time:
          </p>
          <p
            style={{
              fontSize: "1.3rem",
              marginTop: "0.5rem",
            }}
          >{upcomingSession.startSession}<span style={{
              fontSize: "0.3rem", position:"relative", bottom:"4px", margin:"0 1rem"
            }}>⚫</span>{upcomingSession.session_date}</p>
        </div>
      </li>

    </>
  );
};

export default UpcomingMentorSession;
