import React, { useEffect } from "react";
import axios from "axios";

export const AdminStructure1 = ({
  api,
  setIsLoading,
  setApiData,
  setErrorMessage,
  children,
}) => {
  useEffect(() => {
    const fetchProgram = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(api);
        setApiData(response.data);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchProgram();
  }, [api, setIsLoading, setApiData, setErrorMessage]);

  return <>{children}</>;
};

export function Loader() {
  return <p className="loader">Loading...</p>;
}

export function ErrorMessage({ message }) {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
}

export default AdminStructure1;
