import React, { useState } from "react";
import axios from "axios";

const WithdrawalDetails = ({
  setConfirmBox,
  currentItem,
  isLoading,
  setIsLoading,
  setMenu2Active,
  buttonsDisplay,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handlePay = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/updateWithdrawalRequest",
        { id: id }
      );

      if (response.data && response.data.code === 200) {
        setIsLoading(false);
        setConfirmBox(false);
        setMenu2Active(2);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsLoading(false);
    setConfirmBox(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "43rem",
        height: buttonsDisplay === "flex" ? "35rem" : "28rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        left: "53rem",
        top: "20rem",
        pointerEvents: "all",
        zIndex: 999,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
          right: "3rem",
        }}
      >
        <button
          style={{
            background: "none",
            border: "none",
            padding: "0",
          }}
          onClick={handleCancel}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 0.75L0.75 11.25M0.75 0.75L11.25 11.25"
              stroke="#C30532"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      {errorMessage ? (
        <ErrorMessage message={`Error : ${errorMessage}`} />
      ) : (
        <div>
          <p
            style={{
              fontSize: "3rem",
              textAlign: "center",
              marginTop: "4rem",
            }}
          >
            {currentItem.fullName}
          </p>
          <p
            style={{
              fontSize: "1.5rem",
              textAlign: "left",
              marginTop: "3rem",
              marginLeft: "4.1rem",
            }}
          >
            Bank Account Name :{" "}
            <span
              style={{
                marginLeft: "0.4rem",
              }}
            >
              {currentItem.bankDetails.bankAccountName}
            </span>
          </p>
          <p
            style={{
              fontSize: "1.5rem",
              textAlign: "left",
              marginTop: "1rem",
              marginLeft: "4.1rem",
            }}
          >
            Bank Account Number :{" "}
            <span
              style={{
                marginLeft: "0.4rem",
              }}
            >
              {currentItem.bankDetails.bankAccountNumber}
            </span>
          </p>
          <p
            style={{
              fontSize: "1.5rem",
              textAlign: "left",
              marginTop: "1rem",
              marginLeft: "4.1rem",
            }}
          >
            Bank Name :{" "}
            <span
              style={{
                marginLeft: "0.4rem",
              }}
            >
              {currentItem.bankDetails.bankName}
            </span>
          </p>
          <p
            style={{
              fontSize: "1.5rem",
              textAlign: "left",
              marginTop: "1rem",
              marginLeft: "4.1rem",
            }}
          >
            Routing Number :{" "}
            <span
              style={{
                marginLeft: "0.4rem",
              }}
            >
              {currentItem.bankDetails.routingNumber
                ? currentItem.bankDetails.routingNumber
                : "N/A"}
            </span>
          </p>
          <div
            style={{
              margin: "2.6rem 0 1rem 0",
              display: buttonsDisplay,
              gap: "2rem",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                width: "46%",
                marginLeft: "2rem",
                fontWeight: "700",
                background: "none",
                color: "#c3053c",
                textTransform: "none",
                fontSize: "1.2rem",
              }}
              onClick={() => setConfirmBox(false)}
            >
              Cancel
            </button>

            <button
              style={{
                marginLeft: "0",
                marginRight: "2rem",
                width: "46%",
                fontWeight: "700",
                textTransform: "none",
                fontSize: "1.2rem",
                cursor: isLoading ? "not-allowed" : "pointer",
              }}
              className="btn2Style"
              onClick={() => handlePay(currentItem.id)}
            >
              Paid
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default WithdrawalDetails;
