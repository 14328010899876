import ProgressBar from "react-bootstrap/ProgressBar";
import './custom-bootstrap.scss';

const SegmentedProgressBar = ({
  totalRounds,
  roundsLeft,
  completedColor,
  leftColor,
}) => {
  const completedPercent = ((totalRounds - roundsLeft) / totalRounds) * 100;
  const leftPercent = (roundsLeft / totalRounds) * 100;

  return (
    <ProgressBar style={{ height: "0.5rem" }}>
      <ProgressBar
        now={completedPercent}
        key="completed"
        style={{ backgroundColor: completedColor, borderRadius:"10px 0 0 10px" }}
      />
      <ProgressBar
        now={leftPercent}
        key="left"
        style={{ backgroundColor: leftColor, borderRadius:"0 10px 10px 0" }}
      />
    </ProgressBar>
  );
};

export default SegmentedProgressBar;