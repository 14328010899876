import React, { useEffect, useState, useRef } from "react";
import Button from "./Button";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";
import Pusher from "pusher-js";
import FilePreview from "./FilePreview";
import SearchComponent from "./SearchComponent";
import { ErrorMessage, Loader } from "./AdminStructure1";

const defaultAdminMessage = {
  id: "",
  messageType: "message",
  message: "",
  sender: "Admin",
  file: "",
};

const AdminChatSupport = ({isActive}) => {
  const [adminMessage, setAdminMessage] = useState(defaultAdminMessage);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [chatMessage, setChatMessage] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const pickerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState("");

  useEffect(() => {
    const pusher = new Pusher("b9a14c8faacdd7bc2fe1", {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe("chatbox");

    channel.bind("App\\Events\\MessageSend", (data) => {
      console.log("New message received:", data);
      if (data.module === "admin") {
        const fetchUsers = async () => {
          try {
            const response = await axios.post(
              "https://9jadelivery.com/femaletechMobile/public/api/user",
              { module: "Admin" }
            );
            if (response.data) {
              setAllUsers(response.data);
            }
          } catch (error) {
            // Handle error
          }
        };

        fetchUsers();
      }
    });

    return () => {
      pusher.unsubscribe("App\\Events\\MessageSend");
    };
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/user",
          { module: "Admin" }
        );
        if (response.data) {
          setAllUsers(response.data);
          console.log(response.data)
        }
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [setIsLoading, setAllUsers, setErrorMessage]);

  useEffect(() => {
    const handleClickOutsidePicker = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsidePicker);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePicker);
    };
  }, [pickerRef]);

  const handleFileUpload = (event) => {
    const fileData = event.target.files[0];

    if (!fileData) {
      return;
    }

    const fileSizeInMB = fileData.size / (1024 * 1024);

    if (fileSizeInMB > 5) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const fileType = determineFileType(fileData);
      setAdminMessage({
        ...adminMessage,
        file: fileData,
        fileName: fileData.name,
        messageType: fileType,
        message: "",
      });
      setFileUpload(true);
      setFile(fileData);
    };

    reader.readAsDataURL(fileData);
  };

  const determineFileType = (fileData) => {
    if (fileData.type.startsWith("image/")) {
      return "image";
    } else if (fileData.type.startsWith("audio/")) {
      return "audio";
    } else if (fileData.type.startsWith("video/")) {
      return "video";
    } else {
      return "document";
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleEmojiSelect = (object) => {
    const emojiMessage = adminMessage.message + object.emoji;
    setAdminMessage({
      ...adminMessage,
      messageType: "message",
      message: emojiMessage,
      file: "",
    });
    setShowPicker(false);
  };

  const handleInputChange = (value) => {
    setAdminMessage({
      ...adminMessage,
      messageType: "message",
      message: value,
      file: "",
    });
  };

  const handleChatMessages = async (user) => {
    try {
      await fetchmessage(user);
      await clearCounter(user);
      await fetchUsers(user);
    } catch (error) {
      console.error("Error handling chat messages:", error);
    }
  };

  const fetchmessage = async (user) => {
    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/userMessage",
        { userId: user.id, module: "Admin" }
      );

      if (response.data) {
        setChatMessage(response.data);
        setUserDetails(user);
        setAdminMessage({
          ...adminMessage,
          id: user.id,
          messageType: "message",
          message: "",
          sender: "Admin",
          file: "",
        });
        setFileUpload(false);
      }
    } catch (error) {
      console.error("Error fetching program:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  const clearCounter = async (user) => {
    try {
      await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/adminReadMessage",
        { id: user.id }
      );
    } catch (error) {
      console.error("Error clearing counter:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  const fetchUsers = async (user) => {
    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/user",
        {
          module: "Admin",
        }
      );
      if (response.data) {
        setAllUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  const handleAdminMessageSubmit = (e) => {
    e.preventDefault();
    sendMessage(adminMessage);
    setAdminMessage(defaultAdminMessage);
  };

  const sendMessage = async (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      await postAdminMessage(formData);
      handleChatMessages(userDetails);
    } catch (err) {
      console.log(err.message);
    }
  };

  const postAdminMessage = async (formData) => {
    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/adminChat",
        formData
      );
      return response;
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <section className="chat-support">
          <div>
            <SearchComponent allUsers={allUsers} setAllUsers={setAllUsers} isActive = {isActive} />
            <ChatList
              allUsers={allUsers}
              handleChatMessages={handleChatMessages}
            />
          </div>
          <div className="real-chat">
            <header>
              {userDetails && (
                <Person
                  user={userDetails}
                  className="chat-preview user-chat"
                  availability="Online"
                />
              )}
              <svg
                width="3"
                height="15"
                viewBox="0 0 4 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 4.375C0.7835 4.375 0 3.5915 0 2.625C0 1.6585 0.7835 0.875 1.75 0.875C2.7165 0.875 3.5 1.6585 3.5 2.625C3.5 3.5915 2.7165 4.375 1.75 4.375ZM1.75 10.375C0.7835 10.375 0 9.5915 0 8.625C0 7.6585 0.7835 6.875 1.75 6.875C2.7165 6.875 3.5 7.6585 3.5 8.625C3.5 9.5915 2.7165 10.375 1.75 10.375ZM0 14.625C0 15.5915 0.7835 16.375 1.75 16.375C2.7165 16.375 3.5 15.5915 3.5 14.625C3.5 13.6585 2.7165 12.875 1.75 12.875C0.7835 12.875 0 13.6585 0 14.625Z"
                  fill="#212121"
                />
              </svg>
            </header>
            <div>
              <AdminAndUserMessageBox chat={chatMessage} />
            </div>

            <form
              onSubmit={handleAdminMessageSubmit}
              className="real-chat-input"
            >
              {fileUpload && (
                <FilePreview
                  file={file}
                  userDetails={userDetails}
                  onChatMessages={handleChatMessages}
                />
              )}
              <div>
                <input
                  type="text"
                  placeholder="Start typing"
                  value={adminMessage.message}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                  accept="image/*, video/*, audio/*, .doc, .docx, .pdf, .txt, .ppt, .pptx"
                />

                {showPicker && (
                  <div ref={pickerRef} className="emoji-picker-wrapper">
                    <EmojiPicker
                      onEmojiClick={handleEmojiSelect}
                      height={500}
                      width={400}
                    />
                  </div>
                )}
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={(e) => setShowPicker(!showPicker)}
                >
                  <ellipse
                    cx="10.6742"
                    cy="10.0713"
                    rx="9.78479"
                    ry="9.23242"
                    stroke="rgba(0, 0, 0, 0.458)"
                    strokeWidth="1.67862"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.1399 13.6521V13.6521C13.3475 13.3368 13.037 13.0097 12.6595 13.0097H10.6744L8.68935 13.0097C8.31187 13.0097 8.00139 13.3368 8.20899 13.6521V13.6521C8.3431 13.8557 8.53967 14.0408 8.78747 14.1966C9.03527 14.3525 9.32945 14.4762 9.65322 14.5605C9.97698 14.6449 10.324 14.6883 10.6744 14.6883C11.0249 14.6883 11.3719 14.6449 11.6957 14.5605C12.0194 14.4762 12.3136 14.3525 12.5614 14.1966C12.8092 14.0408 13.0058 13.8557 13.1399 13.6521Z"
                    stroke="rgba(0, 0, 0, 0.458)"
                    strokeWidth="1.67862"
                  />
                  <ellipse
                    cx="7.11609"
                    cy="8.39302"
                    rx="0.889526"
                    ry="0.839311"
                    stroke="rgba(0, 0, 0, 0.458)"
                    strokeWidth="1.67862"
                  />
                  <ellipse
                    cx="14.2325"
                    cy="8.39302"
                    rx="0.889527"
                    ry="0.839311"
                    stroke="rgba(0, 0, 0, 0.458)"
                    strokeWidth="1.67862"
                  />
                </svg>
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleClick}
                >
                  <path
                    d="M20.25 9.95344L11.4442 18.2622C11.4442 18.2622 7.35574 22.1198 3.5818 18.5589C-0.192139 14.998 3.8963 11.1404 3.8963 11.1404L13.0167 2.53491C13.0167 2.53491 15.5326 0.160979 18.0486 2.53491C20.5645 4.90884 18.0486 7.28277 18.0486 7.28277L9.24271 15.5915C9.24271 15.5915 7.98473 16.7785 6.72675 15.5915C5.46877 14.4046 6.72675 13.2176 6.72675 13.2176L14.9036 5.50232"
                    stroke="rgba(0, 0, 0, 0.458)"
                    strokeWidth="1.67862"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Button text="SEND" className="btn2Style" type="submit" />
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

const ChatList = ({ allUsers, handleChatMessages }) => {
  return (
    <ul>
      {allUsers &&
        allUsers.length > 0 &&
        allUsers.map((item, index) => (
          <ChatItem
            key={index}
            user={item}
            handleChatMessages={handleChatMessages}
          />
        ))}
    </ul>
  );
};

const ChatItem = ({ user, handleChatMessages }) => {
  return (
    <li>
      <Person
        user={user}
        onUserMessages={handleChatMessages}
        className="chat-preview"
      >
        {user.counter > 0 && (
          <p>
            <span>{user.counter}</span>
          </p>
        )}
      </Person>
    </li>
  );
};

const Person = ({
  children,
  user,
  onUserMessages,
  className,
  availability,
}) => {
  let userPicture;
  let firstLetter = user.fullName.charAt(0);
  let url = user.picture;

  if (url.includes("images")) {
    userPicture = (
      <div>
        <img src={user.picture} alt="User" />
      </div>
    );
  } else {
    userPicture = (
      <div
        style={{
          background: "#c30532",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          height: "4rem",
        }}
      >
        <p
          style={{
            color: "#ffffff",
          }}
        >
          {firstLetter}
        </p>
      </div>
    );
  }
  return (
    <>
      <div className={className} onClick={() => onUserMessages(user)}>
        {userPicture}
        <div>
          <h5>{user.fullName}</h5>
          <span className="availability">{availability}</span>
        </div>
      </div>
      {children}
    </>
  );
};

const AdminAndUserMessageBox = ({ chat }) => {
  let prevDate = null;

  return (
    <ul>
      {chat.map((item, index) => {
        const messageType = item.messageType;

        let message;
        let date = item.date;

        if (date === prevDate) {
          date = null;
        } else {
          prevDate = date;
        }

        if (messageType === "image") {
          message = (
            <img
              className={
                item.sender === "Admin"
                  ? "admin-message"
                  : "admin-message user-message"
              }
              src={item.file}
              alt="media-message"
            />
          );
        } else if (messageType === "audio") {
          message = (
            <audio controls style={{ margin: "2rem 0" }}>
              <source
                src={item.file}
                type="audio/mp3"
                className={
                  item.sender === "Admin"
                    ? "user-message"
                    : "user-message admin-message"
                }
              />
              Your browser does not support the audio element. Upload mp3
            </audio>
          );
        } else if (messageType === "video") {
          message = (
            <video controls width="250" height="150">
              <source
                src={item.file}
                type="video/mp4"
                className={
                  item.sender === "Admin"
                    ? "admin-message"
                    : "admin-message user-message"
                }
              />
              Your browser does not support the video element. Upload mp4
            </video>
          );
        } else if (messageType === "document") {
          message = (
            <span
              className={
                item.sender === "Admin"
                  ? "admin-message"
                  : "admin-message user-message"
              }
            >
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "underline",
                }}
                href={item.file}
                download
              >
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.25 9.95344L11.4442 18.2622C11.4442 18.2622 7.35574 22.1198 3.5818 18.5589C-0.192139 14.998 3.8963 11.1404 3.8963 11.1404L13.0167 2.53491C13.0167 2.53491 15.5326 0.160979 18.0486 2.53491C20.5645 4.90884 18.0486 7.28277 18.0486 7.28277L9.24271 15.5915C9.24271 15.5915 7.98473 16.7785 6.72675 15.5915C5.46877 14.4046 6.72675 13.2176 6.72675 13.2176L14.9036 5.50232"
                    stroke="rgba(0, 0, 0, 0.458)"
                    strokeWidth="1.67862"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ marginLeft: "0.6rem" }}>{item.fileName}</span>
              </a>
            </span>
          );
        } else {
          message = (
            <span
              className={
                item.sender === "Admin"
                  ? "admin-message"
                  : "admin-message user-message"
              }
            >
              {item.message}
            </span>
          );
        }

        return (
          <li style={{ display: "block" }} key={index}>
            {date && (
              <div
                style={
                  index !== 0
                    ? { margin: "7rem 0 0 28rem" }
                    : { marginLeft: "28rem" }
                }
              >
                {date}
              </div>
            )}
            <div
              style={
                item.sender !== "Admin"
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }
                  : { display: "block" }
              }
            >
              {message}
              <span
                style={
                  item.sender === "Admin"
                    ? { marginLeft: "19rem", fontSize: "1.2rem" }
                    : { marginRight: "5rem", fontSize: "1.2rem" }
                }
              >
                {item.time}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default AdminChatSupport;
