import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const PushNotification = ({ setIsActive }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedReceiver, setSelectedReceiver] = useState("All");
  const [content, setContent] = useState("");
  const [notificationSubmitted, setNotificationSubmitted] = useState(false);

  // 1. Fetch all users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/user",
          { module: "Admin" }
        );
        if (response.data) {
          setAllUsers(response.data);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    fetchUsers();
  }, []);

  // 2. Send push notification
  const handleSendNotification = async () => {
    const receiverValue = selectedReceiver === "All" ? "All" : selectedReceiver; // user ID if not "All"

    const payload = {
      content,
      receiver: receiverValue,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/sendPushNotification",
        payload
      );
      if (response.data) {
        setNotificationSubmitted(true);
        setIsLoading(false);
      }
      console.log("Notification sent:", response.data);
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error sending notification:", error);
      setIsLoading(false);
    }

    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  const handleFormHomeBtn = () => {
    setIsActive(1);
  };

  return (
    <div style={{ padding: "2rem" }}>
      {!notificationSubmitted ? (
        <div className="push-notification">
          <h2 style={{ marginBottom: "0.5rem" }}>Push Notification</h2>

          {/* 3. Dropdown to select "All" or a specific user */}
          <label htmlFor="receiverSelect">
            Select who receives the notification
          </label>
          <select
            id="receiverSelect"
            value={selectedReceiver}
            onChange={(e) => setSelectedReceiver(e.target.value)}
            style={{
              display: "block",
              margin: "1rem 0",
              width: "21rem",
              height: "3.5rem",
              padding: "0.4rem",
              borderRadius: "4px",
              border: "1px solid rgb(182, 182, 182)",
              outline: "none",
              backgroundColor: "#f2f2f2",
            }}
          >
            <option value="All">All</option>
            {allUsers.map((user) => (
              <option key={user.id} value={user.id}>
                {user.fullName}
              </option>
            ))}
          </select>

          {/* 4. Textarea for the notification message */}
          <div style={{ marginTop: "2rem" }}>
            <label htmlFor="content">Message</label>
            <textarea
              id="content"
              placeholder="Type your message here"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              style={{
                display: "block",
                height: "25rem",
                width: "45rem",
                marginTop: "0.5rem",
                padding: "1rem",
                borderRadius: "6px",
                border: "1px solid rgb(182, 182, 182)",
                outline: "none",
                backgroundColor: "#f2f2f2",
              }}
            />
          </div>

          {/* 5. Button to send the notification */}
          <button
            onClick={handleSendNotification}
            disabled={isLoading}
            className="continue btn2Style"
            style={{
              marginTop: "2rem",
              width: "20rem",
              cursor: isLoading ? "not-allowed" : "pointer",
            }}
          >
            Send Notification
          </button>
        </div>
      ) : (
        <FormSuccessfulMessage
          text={"Notification sent successfully"}
          onclick={handleFormHomeBtn}
          btnstyle={{ marginLeft: "17rem" }}
        />
      )}

      {errorMessage ? <ErrorMessage message={errorMessage} /> : ""}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default PushNotification;
