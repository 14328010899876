import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorMessage, Loader } from "./AdminStructure1";
import ClubPlaceholder from "../assets/images/resource.png";

export const Clubs = ({
  allMentorInfo,
  setAllMentorInfo,
  apiData,
  isLoading,
  setIsLoading,
  errorMessage,
}) => {
  const [selectedClubId, setSelectedClubId] = useState(null);

  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="mentorship-page resource-page">
          {apiData &&
            apiData.length > 0 &&
            apiData.map((item, index) => (
              <Club
                club={item}
                key={index}
                allMentorInfo={allMentorInfo}
                setAllMentorInfo={setAllMentorInfo}
                selectedClubId={selectedClubId}
                setSelectedClubId={setSelectedClubId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const Club = ({
  club,
  allMentorInfo,
  setAllMentorInfo,
  selectedClubId,
  setSelectedClubId,
}) => {
  const handleLearnMoreClick = () => {
    setSelectedClubId(club.id);
    setAllMentorInfo(true);
  };

  return (
    <>
      <li
        style={{
          width: "35rem",
          height: "17rem",
          cursor: "pointer",
        }}
        onClick={handleLearnMoreClick}
      >
        <div style={{ width: "52%", position: "static", padding: "0" }}>
          <img
            src={
              club.picture.includes("images")
                ? club.picture
                : ClubPlaceholder
            }
            style={{
              width: "100%",
              height: "152px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
            alt="Applicant Cover"
            className="mindfulness-cover"
          />
        </div>
        <div
          style={{
            width: "80%",
          }}
        >
          <h3
            style={{
              fontSize: "1.35rem",
              lineHeight: "1.3em",
              fontWeight: "600",
              marginBottom: "0.5rem",
            }}
          >
            {club.clubName}
          </h3>
          <p
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.25em",
              color: "#212529",
            }}
          >
            {club.clubDescription}
          </p>

          <div
            style={{
              marginTop: "1.5rem",
            }}
          >
            <h5
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                marginBottom: "0.8rem",
                color: "#C3053C",
              }}
            >
              Admin
            </h5>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}
            >
              <div style={{ width: "15%" }}>
                <img
                  src={
                    club.adminPicture.includes("images")
                      ? club.adminPicture
                      : ClubPlaceholder
                  }
                  style={{
                    borderRadius: "100px",
                    height: "3rem",
                    objectFit: "cover",
                  }}
                  alt="Applicant Cover"
                  className="mindfulness-cover"
                />
              </div>
              <div>
                <h4
                  style={{
                    fontSize: "1.15rem",
                    lineHeight: "1.3em",
                    fontWeight: "600",
                  }}
                >
                  {club.adminName}
                </h4>
                <p
                  style={{
                    fontSize: "0.9rem",
                    color: "#828282",
                    lineHeight: "1.25em",
                  }}
                >
                  {club.adminEmail}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>

      {allMentorInfo && selectedClubId === club.id && (
        <ClubInformation
          club={club}
          setAllMentorInfo={setAllMentorInfo}
        />
      )}
    </>
  );
};

const ClubInformation = ({
  club,
  setAllMentorInfo,
}) => {
  const [clubMembers, setClubMembers] = useState([]);
  const [memberIsLoading, setMemberIsLoading] = useState(null);

  useEffect(() => {
    const fetchClubMembers = async () => {
      setMemberIsLoading(true);
      try {
        const response = await axios.get(
          "https://9jadelivery.com/femaletechMobile/public/api/getClubMembers",
          {
            params: {
              clubId: club.id,
            },
          }
        );
        setMemberIsLoading(false);
        setClubMembers(response.data);
      } catch (error) {
        setMemberIsLoading(false);
        console.log(error);
      }
    };

    fetchClubMembers();
  }, [setClubMembers, club.id, setMemberIsLoading]);

  return (
    <div
      style={{
        position: "absolute",
        width: "38rem",
        height: "58.2rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        top: "8rem",
        zIndex: "999",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1.4rem 2rem 1.2rem",
          borderBottom: "1px solid #D7D7D7",
        }}
      >
        <p
          style={{
            fontSize: "1.7rem",
            fontWeight: "600",
            color: "#212529",
          }}
        >
          Suspend Club
        </p>
        <ToggleSwitch club={club} />
      </div>
     <div style={{
           padding: "0 2rem 1.8rem 2rem",
        }}>
     <div
        style={{
          marginBottom: "2.2rem",
          marginTop: "1.2rem",
        }}
      >
        <div style={{ width: "25%", position: "static", padding: "0" }}>
          <img
            src={
              club.picture.includes("images")
                ? club.picture
                : ClubPlaceholder
            }
            style={{
              width: "100%",
              borderRadius: "5px",
              height: "80px",
              objectFit: "cover",
            }}
            alt="Applicant Cover"
            className="mindfulness-cover"
          />
        </div>
        <h3
          style={{
            fontSize: "1.35rem",
            lineHeight: "1.3em",
            fontWeight: "600",
            marginTop: "1.2rem",
          }}
        >
          {club.clubName}
        </h3>
      </div>
      <div>
        <h4
          style={{
            fontSize: "1.2rem",
            lineHeight: "1.3em",
            fontWeight: "600",
            marginBottom: "0.5rem",
          }}
        >
          About
        </h4>
        <p
          style={{
            fontSize: "1.1rem",
            lineHeight: "1.25em",
            color: "#212529",
          }}
        >
          {club.clubDescription}
        </p>
      </div>
      <div
        style={{
          marginTop: "1.8rem",
        }}
      >
        <h5
          style={{
            fontSize: "1rem",
            fontWeight: "600",
            marginBottom: "0.8rem",
            color: "#C3053C",
          }}
        >
          Admin
        </h5>
        <div style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
          <div style={{ width: "10%" }}>
            <img
              src={
                club.adminPicture.includes("images")
                  ? club.adminPicture
                  : ClubPlaceholder
              }
              style={{
                borderRadius: "100px",
                height: "3rem",
                objectFit: "cover",
              }}
              alt="Applicant Cover"
              className="mindfulness-cover"
            />
          </div>
          <div>
            <h4
              style={{
                fontSize: "1.25rem",
                lineHeight: "1.3em",
                fontWeight: "600",
              }}
            >
              {club.adminName}
            </h4>
            <p
              style={{
                fontSize: "1rem",
                color: "#828282",
                lineHeight: "1.25em",
              }}
            >
              {club.adminEmail}
            </p>
          </div>
        </div>
      </div>
      <AllClubMembers
        membersData={clubMembers}
        memberIsLoading={memberIsLoading}
      />
      <div
        style={{
          marginTop: "4rem",
        }}
      >
        <button
          style={{
            padding: "0.7rem 1rem",
            background: "#C30532",
            color: "#FFFFFF",
            width: "100%",
            textTransform: "none",
            fontSize: "1.3rem",
          }}
          onClick={() => setAllMentorInfo(false)}
        >
          Cancel
        </button>
      </div>
     </div>
    </div>
  );
};

const AllClubMembers = ({ membersData, memberIsLoading }) => {
  return (
    <div
      style={{
        marginTop: "2.8rem",
      }}
    >
      <h5
        style={{
          fontSize: "1.2rem",
          fontWeight: "600",
          marginBottom: "0.8rem",
        }}
      >
        Members
      </h5>
      {memberIsLoading ? (
        <Loader />
      ) : (
        <ul
          style={{
            display: "block",
          }}
        >
          {membersData &&
            membersData.length > 0 &&
            membersData.map((item, index) => (
              <ClubMemberItem member={item} key={index} />
            ))}
        </ul>
      )}
    </div>
  );
};

const ClubMemberItem = ({ member }) => {
  return (
    <li
      style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #E2E2E2",
        margin: "1rem 0",
        padding: "1rem",
      }}
    >
      <div
        style={{
          width: "8%",
          position: "static",
          padding: "0",
        }}
      >
        <img
          src={
            member.picture.includes("images")
              ? member.picture
              : ClubPlaceholder
          }
          style={{
            width: "100%",
            borderRadius: "100px",
            height: "2.5rem",
            objectFit: "cover",
          }}
          alt="member"
        />
      </div>
      <div>
        <h6
          style={{
            fontSize: "1.15rem",
            fontWeight: "400",
            color: "#212529",
          }}
        >
          {member.fullName}
        </h6>
        <p
          style={{
            fontSize: "0.9rem",
            color: "#828282",
          }}
        >
          {member.email}
        </p>
      </div>
    </li>
  );
};

const ToggleSwitch = ({ club }) => {
  const [isOn, setIsOn] = useState(false);
  const [toggleIsLoading, setToggleIsLoading] = useState(false);

  const storedAdminEmail = localStorage.getItem("adminEmail");

  useEffect(() => {
    if (club.disable === 0 || club.disable === "0") {
      setIsOn(false);
    } else if (club.disable === 1 || club.disable === "1") {
      setIsOn(true);
    }
  }, [club.disable]);

  const handleToggle = async (clubId) => { 
      try {
        setToggleIsLoading(true);
        const response = await axios.patch(
          "https://9jadelivery.com/femaletechMobile/public/api/adminDisableClub",
          {
            email: storedAdminEmail,             
            canDisableClub: true,
            clubId,
            disable: !isOn
          }
        );

        if (response.data && response.data.code === 200) {
          setIsOn(!isOn);
          setToggleIsLoading(false);
        } else {
          console.log("Failed to toggle suspension: ", response.data);
          setToggleIsLoading(false);
        }
      } catch (error) {
        console.log(error.message);
        setToggleIsLoading(false);
      }
  };

  return (
    <div
      className="toggle-switch"
      onClick={() => !toggleIsLoading && handleToggle(club.id, club.adminEmail)}
      style={{ cursor: toggleIsLoading ? "not-allowed" : "pointer" }}
    >
      <div className={`slider ${isOn ? "suspend-club" : ""}`}></div>
    </div>
  );
};

export default Clubs;
