import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const FundHerForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  setCreateFundHer,
}) => {
  const [fundHerFormData, setFundHerFormData] = useState({
    fundHer: [
      {
        question: "",
        option: [],
      },
    ],
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    if (editMode) {
      setFundHerFormData((prevFormData) => ({
        ...prevFormData,
        id: itemToEdit.id,
        fundHer: itemToEdit.data,
      }));
    }
  }, [editMode, itemToEdit, itemToEdit.typeOfContent]);

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...fundHerFormData.fundHer];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      [field]: field === "option" ? value.split(",") : value,
    };
    setFundHerFormData((prevState) => ({
      ...prevState,
      fundHer: updatedQuestions,
    }));
  };

  const addQuestion = () => {
    setFundHerFormData((prevState) => ({
      ...prevState,
      fundHer: [
        ...prevState.fundHer,
        {
          question: "",
          option: [],
        },
      ],
    }));
  };

  const deleteDynamicInput = (indexToRemove, arrayName) => {
    setFundHerFormData((prevState) => ({
      ...prevState,
      [arrayName]: prevState[arrayName].filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const postProgram = async (formData) => {
    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/createFundHer",
        formData
      );
      return response;
    } catch (error) {
      console.error("Error posting program:", error.message);
      setInputError(error.message);
    }
  };

  const editProgram = async (formData) => {
    try {
      const response = await axios.post(
        `https://9jadelivery.com/femaletechMobile/public/api/createFundHer`,
        formData
      );

      return response;
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const addProgram = async (payload) => {
    try {
      editMode ? await editProgram(payload) : await postProgram(payload);
      setFormSubmitted(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addProgram(fundHerFormData);

    setInputError(false);
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(2);
  };

  return (
    <div className="programme-form">
      <form onSubmit={handleSubmit}>
        {!formSubmitted && (
          <div style={{ height: "50rem", overflow: "auto" }}>
            {fundHerFormData.fundHer.map((field, index) => (
              <div key={index}>
                <label style={{ fontWeight: "700" }}>
                  Question {index + 1}:
                  <input
                    type="text"
                    value={field.question}
                    onChange={(e) =>
                      handleQuestionChange(index, "question", e.target.value)
                    }
                    style={{ marginBottom: "1.5rem" }}
                    required
                  />
                </label>
                <label style={{ lineHeight: "2.1rem" }}>
                  Please separate each option with a comma(,) <br />
                  Leave empty if it does not have options.
                  <textarea
                    value={field.option.join(",")}
                    onChange={(e) =>
                      handleQuestionChange(index, "option", e.target.value)
                    }
                    placeholder="Options"
                    style={{ height: "10rem" }}
                  />
                </label>
                <div
                  style={{
                    display: "flex",
                    marginTop: "1rem",
                  }}
                >
                  <button
                    type="button"
                    onClick={addQuestion}
                    style={
                      index === fundHerFormData.fundHer.length - 1
                        ? {
                            width: "18%",
                            backgroundColor: "#c3053c",
                            color: "#ffffff",
                            textAlign: "center",
                          }
                        : {
                            display: "none",
                          }
                    }
                  >
                    + Add another question
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteDynamicInput(index, "fundHer")}
                    style={
                      index === fundHerFormData.fundHer.length - 1
                        ? {
                            width: "15.2%",
                            marginLeft: "6.8rem",
                          }
                        : {
                            width: "15.2%",
                            marginLeft: "26rem",
                          }
                    }
                  >
                    x Delete question
                  </button>
                </div>
              </div>
            ))}

            <br />
            <button
              className="continue btn2Style"
              onClick={() => setCreateFundHer(false)}
            >
              Cancel
            </button>
            <button type="submit" className="continue btn2Style">
              {editMode ? "Update form" : "Submit"}
            </button>
            {inputError && (
              <p className="input-error">⛔ Please fill in all fields</p>
            )}
          </div>
        )}
        {formSubmitted && (
          <FormSuccessfulMessage
            text={
              editMode
                ? "Questions updated successfully"
                : "Questions added successfully"
            }
            onclick={handleFormHomeBtn}
          />
        )}
      </form>
    </div>
  );
};

export default FundHerForm;
