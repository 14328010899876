import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchComponent from "./SearchComponent";

const AllMembers = ({
  mobileAnalytics,
  setConfirmBox,
  setConfirmBox2,
  membersCardNumber,
  setCurrentMember,
}) => {
  const [allMembers, setAllmembers] = useState([]);
  const [usersLength, setUsersLength] = useState(0);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (membersCardNumber === 1) {
      setUsersLength(mobileAnalytics?.allUser.length);
      setUserType("Total members");
    } else if (membersCardNumber === 2) {
      setUsersLength(mobileAnalytics?.partnerOrBusinessFounder.length);
      setUserType("Partners");
    } else if (membersCardNumber === 3) {
      setUsersLength(mobileAnalytics?.freeMembers.length);
      setUserType("Founderly members");
    }
  }, [membersCardNumber, mobileAnalytics]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/analytics"
        );
        if (membersCardNumber === 1) {
          setAllmembers(response.data?.allUser);
        } else if (membersCardNumber === 2) {
          setAllmembers(response.data?.partnerOrBusinessFounder);
        } else if (membersCardNumber === 3) {
          setAllmembers(response.data?.freeMembers);
        }
      } catch (error) {}
    };

    fetchAnalytics();
  }, [setAllmembers, membersCardNumber]);

  const fetchMembers = async () => {
    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/analytics"
      );
      if (response.data) {
        if (membersCardNumber === 1) {
          setAllmembers(response.data?.allUser);
        } else if (membersCardNumber === 2) {
          setAllmembers(response.data?.partnerOrBusinessFounder);
        } else if (membersCardNumber === 3) {
          setAllmembers(response.data?.freeMembers);
        }
      }
    } catch (error) {
      // Handle error
    }
  };

  const exportToCSV = () => {
    // Define the headers
    const headers = ["Name", "Email", "Profile", "Status"];

    // Map the data to CSV format
    const rows = allMembers.map((member) => [
      member.fullName,
      member.email,
      member.whatDoYouDo || "Nill",
      member.suspended === "0" || member.suspended === 0 || member.suspended === null ? "Active" : "Suspended",
    ]);

    // Create the CSV content
    let csvContent = headers.join(",") + "\n";
    csvContent += rows.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV content and download it
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${userType}-List.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        width: "75rem",
        height: "65rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        left: "32rem",
        top: "3rem",
        pointerEvents: "all",
        zIndex: 999,
        padding: "1.8rem",
      }}
      className="all-members"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2.5rem",
        }}
      >
        <h2
          style={{
            fontSize: "1.85rem",
            fontWeight: "700",
          }}
        >{`${usersLength} ${userType}`}</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1.6rem",
          }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              border: "none",
              background: "#c30532",
              color: "#ffffff",
              borderRadius: "4px",
              padding: "0.8rem",
              textTransform: "none",
              fontSize: "1rem",
            }}
            onClick={exportToCSV}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.81216 0.25C7.09694 0.25 7.33237 0.461508 7.36964 0.73606L7.37481 0.812388L7.37571 6.25H12.8155C13.1262 6.25 13.378 6.50185 13.378 6.8125C13.378 7.09727 13.1664 7.33263 12.8919 7.3699L12.8155 7.375H7.37571L7.37721 12.8118C7.37729 13.1225 7.12551 13.3745 6.81486 13.3745C6.53009 13.3745 6.29466 13.163 6.25739 12.8884L6.25221 12.812L6.25071 7.375H0.813965C0.5033 7.375 0.251465 7.12315 0.251465 6.8125C0.251465 6.52773 0.463077 6.29237 0.737637 6.2551L0.813965 6.25H6.25071L6.24981 0.812612C6.24974 0.501955 6.50151 0.25 6.81216 0.25Z"
                fill="white"
              />
            </svg>
            <span>Export List</span>
          </button>
          <button
            style={{
              border: "none",
              background: "none",
              padding: "0",
            }}
            onClick={() => setConfirmBox(false)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 6L18.7742 18.7742"
                stroke="#14142B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 18.7734L18.7742 5.99924"
                stroke="#14142B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <SearchComponent
          allUsers={allMembers}
          setAllUsers={setAllmembers}
          membersCardNumber={membersCardNumber}
          fetchMembers={fetchMembers}
        />
      </div>
      <table className="all-members recent-actions">
        <thead>
          <tr
            style={{
              borderBottom: "1px solid #d5d5d5",
            }}
          >
            <th
              colSpan="2"
              style={{
                width: "29%",
                paddingLeft: "1.2rem",
              }}
            >
              Name
            </th>
            <th
              colSpan="2"
              style={{
                width: "30%",
              }}
            >
              Email
            </th>
            <th
              colSpan="2"
              style={{
                width: "20%",
              }}
            >
              Profile
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allMembers.map((item, index) => {
            return (
              <TableRow
                key={index}
                member={item}
                setConfirmBox={setConfirmBox}
                setConfirmBox2={setConfirmBox2}
                setCurrentMember={setCurrentMember}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TableRow = ({
  member,
  setConfirmBox,
  setConfirmBox2,
  setCurrentMember,
}) => {
  const fetchUserProfile = async (id) => {
    try {
      const response = await axios.get(
        `https://9jadelivery.com/femaletechMobile/public/api/getUserProfile`,
        {
          params: {
            userId: id,
          },
        }
      );
      setCurrentMember(response.data);
    } catch (error) {}
  };

  let firstLetter = member.fullName?.charAt(0);
  let memberPicture;

  if (member.picture?.includes("images")) {
    if (member.picture?.includes("app")) {
      memberPicture = (
        <div
          style={{
            width: "3rem",
          }}
        >
          <img
            src={member.picture}
            alt="Member"
            style={{
              width: "100%",
              borderRadius: "4px",
            }}
          />
        </div>
      );
    } else {
      memberPicture = (
        <div
          style={{
            width: "3rem",
          }}
        >
          <img
            src={
              "https://9jadelivery.com/femaletechMobile/storage/app/" +
              member.picture
            }
            alt="Member"
            style={{
              width: "100%",
              borderRadius: "4px",
            }}
          />
        </div>
      );
    }
  } else {
    memberPicture = (
      <div
        style={{
          background: "#c30532",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          height: "3rem",
          width: "3rem",
        }}
      >
        <p
          style={{
            color: "#ffffff",
            fontSize: "1.2rem",
          }}
        >
          {firstLetter}
        </p>
      </div>
    );
  }

  return (
    <tr>
      <td
        style={{
          paddingLeft: "1.2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span>{memberPicture}</span>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setConfirmBox(false);
              setConfirmBox2(true);
              fetchUserProfile(member.id);
            }}
          >
            {member.fullName}
          </span>
        </div>
      </td>
      <td></td>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        {member.email}
      </td>
      <td></td>
      <td
        style={{
          fontSize: "1.2rem",
          verticalAlign: "middle",
        }}
      >
        {member.whatDoYouDo ? member.whatDoYouDo : "Nill"}
      </td>
      <td></td>
      <td
        style={{
          fontSize: "1.2rem",
          fontWeight: "500",
          verticalAlign: "middle",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span>Suspend</span>
          <ToggleSwitch member={member} />
        </div>
      </td>
    </tr>
  );
};

export const ToggleSwitch = ({ member }) => {
  const [isOn, setIsOn] = useState(false);
  const [toggleIsLoading, setToggleIsLoading] = useState(false);

  useEffect(() => {
    if (member.suspended === 0 || member.suspended === "0") {
      setIsOn(false);
    } else if (member.suspended === 1 || member.suspended === "1") {
      setIsOn(true);
    }
  }, [member.suspended]);
  
  const handleToggle = async (id) => {
    try {
      setToggleIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/suspendMember",
        { userId: id, status: !isOn }
      );

      if (response.data && response.data.code === 200) {
        setIsOn(!isOn);
        setToggleIsLoading(false);
      } else {
        console.log("Failed to toggle suspension: ", response.data);
        setToggleIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setToggleIsLoading(false);
    }
  };

  return (
    <div
      className="toggle-switch"
      onClick={() => !toggleIsLoading && handleToggle(member.id)}
      style={{ cursor: toggleIsLoading ? "not-allowed" : "pointer" }}
    >
      <div
        className={`slider ${isOn ? "suspend-member" : ""}`}
      />
    </div>
  );
};

export default AllMembers;
