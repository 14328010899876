const MoveToPastEventsConfirmation = ({
    setConfirmBox,
    setIsPastEventPictures,
  }) => {
    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "43rem",
          height: "25rem",
          borderRadius: "12px",
          background: "#ffffff",
          overflow: "auto",
          left: "53rem",
          top: "20rem",
          pointerEvents: "all",
          zIndex: 999,
        }}
      >
        <p
          style={{
            fontSize: "3rem",
            textAlign: "center",
          }}
        >
          Are you sure <br /> you want to mark as done?
        </p>
        <p
          style={{
            fontSize: "2rem",
            textAlign: "center",
            marginTop: "3rem",
          }}
        >
          The event will be moved to past events.
        </p>
  
        <div
          style={{
            margin: "2.6rem 0 1rem 0",
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              width: "46%",
              marginLeft: "2rem",
              fontWeight: "700",
              background: "none",
              color: "#c3053c",
              textTransform: "none",
              fontSize: "1.2rem",
            }}
            onClick={() => setConfirmBox(false)}
          >
            Cancel
          </button>
  
          <button
            style={{
              marginLeft: "0",
              marginRight: "2rem",
              width: "46%",
              fontWeight: "700",
              textTransform: "none",
              fontSize: "1.2rem",
            }}
            className="btn2Style"
            onClick={() => setIsPastEventPictures(true)}
          >
            Proceed
          </button>
        </div>
      </div>
    );
  };

  export default MoveToPastEventsConfirmation;
